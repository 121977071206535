import React from 'react';

import Layout from 'components/layout';
import Head from 'components/head';
import Section from 'containers/section';
import Title from 'components/title';
import { theme } from 'constants/theme';
import { SwiggleIcon } from 'components/icon';

const NotFound = () => (
  <Layout headerTheme="dark">
    <Head pageTitle="404" />
    <Section bg="#E3C7B1" type="block" height="100vh">
      <SwiggleIcon />
      <Title as="h1" size="giant" color={theme.colors.base} align="center">
        Sorry, I think you might be lost :(
      </Title>
    </Section>
  </Layout>
);

export default NotFound;
